import { inject } from '@angular/core';
import { Router } from '@angular/router';
import type { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const loggedGuard: CanActivateFn = (
	next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	const authProvider = inject(AuthService);

	if (next.data?.onlyPublic) {
		return !authProvider.isLogged;
	} else if (authProvider.isLogged) {
		return true;
	}

	const router = inject(Router);

	// To be able to be back to current URL after login:
	router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
	return false;
};
